<template>
  <div ref="pageBox">
    <LabelContent title="人员管理" ref="LabelContent">
      <div slot="btns">
        <el-button size="small" type="success" @click="tableDetail(res)">新增</el-button>
      </div>
    </LabelContent>
    <div class="tableBox">
      <el-table
        stripe
        ref="multipleTable"
        :data="tableData"
        class="center"
        :height="tableHeight + 'px'"
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
      >
        <el-dialog>
          <span>是否删除？</span>
          <span slot="footer">
            <el-button type="primary">确认</el-button>
            <el-button>取消</el-button>
          </span>
        </el-dialog>
        <el-table-column prop="userName" label="用户名" :sort-method="sortByDate" />
        <el-table-column label="是否启用" show-overflow-tooltip>
          <template slot-scope="scopes">
            <el-switch
              v-model="scopes.row.isActive"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="isActive(scopes.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scopes">
            <i class="editBtn iconfont u-youjianbianji" @click="edit(scopes.row)" />
            <!-- <i @click="dele(row)" class="delBtn iconfont u-shanchu" /> -->
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="修改" :visible.sync="dialogFormVisible">
        <el-form style="display: flex; flex-wrap: wrap">
          <el-form-item label="用户名" :label-width="formLabelWidth" class="stywid">
            <el-input v-model="setadmin.userName" placeholder="请输入内容"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="editRow()">确 定</el-button>
        </div>
      </el-dialog>
      <el-pagination
        @size-change="paginationChange"
        @current-change="paginationChange"
        :current-page="testValue"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="testValue"
        layout="total, sizes, prev, pager, next, jumper"
        :total="testValue"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "Factory",
  data () {
    return {
      addoredit: true,
      scope: "",
      seltime: "", //搜索日期
      selname: "", //搜索用户名
      seladd: "", //搜索地址
      setadmin: {
        userName: "",
      },
      batchDeleteArr: [],
      tableHeight: 0, //表格高度
      testValue: 1,
      tableData: [],
      dialogTableVisible: false,
      dialogFormVisible: false,
      formLabelWidth: "120px",
    }
  },
  mounted () {
    //设置表格高度
    this.$nextTick(() => {
      let page = this.$refs.pageBox.offsetHeight || 0 //卡片高度
      let SearchBox = this.$refs.SearchBox
        ? this.$refs.SearchBox.$el.offsetHeight
        : 0 //搜索框高度
      let LabelContent = this.$refs.LabelContent
        ? this.$refs.LabelContent.$el.offsetHeight
        : 0 //内容标题高度
      this.tableHeight = page - (SearchBox + LabelContent + 20 + 30) //padding -20,分页 -30
    })
    this.init()
  },
  methods: {
    async isActive (row) {
      let result = await this.$http.request({
        url: "/hw/admin/user/disable",
        method: "POST",
        params: {
          isActive: row.isActive,
          userId: row.userId
        },
      })
    },
    //用户信息列表数据
    async init () {
      let that = this
      let result = await that.$http.request({
        url: "/hw/admin/user/admin_user",
        method: "GET",
        params: that.query,
      })
      if (result.status == 100) {
        let { data } = result
        that.tableData = data.list
        that.total = data.total
      }
    },
    async edit (row) {
      this.$router.push({
        path: "/useradmin_details/" + row.userId
      })
    },
    async editRow () {
      let result = await this.$http.request({
        url: "/hw/admin/user/save",
        method: "POST",
        data: {
          userName: this.setadmin.userName,
        },
        headers: {
          "Content-Type": "application/json;charset=UTF-8"
        }
      })
    },
    batchDelete () {
      let deleteData = this.batchDeleteArr.map((item) => {
        const { server_asset_id } = item
        return { server_asset_id }
      })
      this.deleteApi(deleteData)
    },
    deleteApi (data) {
      const _this = this
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => { })
    },
    //分页
    paginationChange (val) {
      console.log(val)
    },
    //表格行过滤
    formatterRow (row, column) {
    },
    //多选框选择 返回当前已选行数据(Array)
    handleSelectionChange (val) {
      this.batchDeleteArr = val
    },
    //从后台获取数据,重新排序
    sortChange (val) {
      // 详细文档参考： https://element.eleme.cn/2.13/#/zh-CN/component/table
      console.log(val)
    },
    //自定义排序规则 sort-method
    sortByDate (a, b) {
      console.log(a, b)
      if (a < b) return -1
    },
    //跳转增加
    tableDetail (row) {
      this.$router.push({
        path: "/useradmin_details/add"
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.stywid {
  width: 40%;
}
</style>
